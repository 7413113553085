import React from 'react'
import PropTypes from 'prop-types';
import Link from 'next/link'
import styled from 'styled-components'
import securePage from '../hocs/securePage'
import api from '../utils/api'
import Header from "../components/Header"
import Router from 'next/router'
import redirect from "../utils/redirect"
import { updateUser } from '../store'
import _ from 'lodash'
import Moment from 'moment'
import { extendMoment } from 'moment-range';
export const moment = extendMoment(Moment);
moment.locale("es")
moment.updateLocale('es', {
    months: [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
        "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ],
    weekdays: [
        "Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"
    ]
});
import Modal from '../utils/modal'
import update from 'immutability-helper'
import NProgress from 'nprogress'
import gql from "graphql-tag"
import { Query, withApollo, graphql } from "react-apollo"

import Formsy from 'formsy-react';
import MyInput from '../components/MyInput';
import autoComplete from '../utils/autocomplete/auto-complete.min.js'
import '../utils/autocomplete/auto-complete.css'

export const getBlockedDays = gql`
  query blockedDays($businesses_id: Int!, $doctors_id: Int, $from: String!, $to: String){
    blockedDays(businesses_id: $businesses_id, doctors_id: $doctors_id, from: $from, to: $to){
        id
        from
        to
        businesses_id
        doctors_id
    }
  }
`;

const Root = styled.div`
  font-family: Roboto, "Helvetica Neue", Helvetica;
  overflow: visible;
`

const IntervalContainer = styled.div`
  position: relative;
  &:last-child{
    margin-bottom: 20px;
  }
`

const Interval = styled.div`
${IntervalContainer}:first-child & {
    border-width: 1px;
  }
  display: flex;
  border-width: 0 ${props => props.descanso ? 0 : "1px"} 1px;
  border-style: solid;
  border-color: #d8d8d8;
  box-sizing: border-box;
  margin-left: ${props => props.showTimes ? "60px" : "-1px"} ;
  position:relative;
  background-color: ${props => {
        if (props.descanso || props.mask)
            return "transparent"
        if (props.clicked)
            return "#9cc0d8"
        if (props.hideCitas)
            return "#ffffff"
        if (props.blocked || props.blockedByUser || props.blockedMoment)
            return "#C4C4C4"
        if (props.expired)
            return "#f5f5f5"
        return "#ffffff"
    }} ;
    .no-touch &:hover  {
    cursor: ${props => props.descanso || props.blocked || props.mask || props.blockedByUser || !props.active ? "default" : "pointer"};
    ${props => !props.blocked && !props.mask && !props.blockedByUser && props.active && "background-color: #9cc0d8; z-index:2;"}
  }
`

const StartingTime = styled.div`

`
const EndingTime = styled.div`
    display: ${props => props.last ? "block" : "none"};
`

const WrapperHorizontal = styled.div`
  display: flex;
  flex-direction: row;
`

const CitaPosition = styled.div`
  text-align:center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 48px;
  padding:5px;
    box-sizing: border-box;
    font-family:"Open Sans";
    font-size: 14px;
  color: #fff;
  pointer-events: none;
`

const CitaContainer = styled.div`
    ${IntervalContainer}:first-child &{
        border-top: 2px solid transparent;
    }
    position: absolute;
  top:0;
  left:55px;
    z-index: 10;
      right: 0;
      box-sizing: border-box;
      border-top: 1px solid transparent;
      border-bottom: 2px solid transparent;
`

const Cita = styled.div`
  height: 100%;
  background-color: rgba(12, 25, 38, .7);
  border-left: 5px solid ${props => props.status == "active" ? "rgba(146, 0, 0, 0.7)" : "rgba(6, 169, 23, .7)"} ;
  background-clip: padding-box;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 20px;
  .no-touch &:hover{
    cursor: ${props => props.noClick ? "default" : "pointer"};
    ${props => !props.noClick ? "background-color: rgba(24, 70, 138, .7)" : null};
  }
`

const Service = styled.li`
  color: ${props => props.available ? "#000" : "#9A9A9A"};
`

const Mask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #000;
  opacity: .5;
  z-index: 11;
`

const Expired = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #024477;
  opacity: .5;
  z-index: 11;
`

const MainWrapper = styled(WrapperHorizontal)`
  margin: 10px 0 0;
`

const TimeInterval = styled.div`
    position: relative;
`

const DayContainer = styled.div`
position:relative;
  flex:1;
  display: flex;
    flex-direction: column;
    ${props => {
        if (props.type == 1)
            return `border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #efefef;
    padding-right: 5px;
    margin-right: 5px;
    &:last-child{
    border-right-width: 0;
    padding-right: 0;
    margin-right: 0;
  }`
    }}
`

const DayTitle = styled.div`
font-family: Roboto,"Helvetica Neue",Helvetica;
${props => props.today && "font-weight:500;"}
    grid-column-start: 2;
flex:1;
color: ${props => props.type == 1 ? "#0c4f74" : props.blocked ? "#c84b4e" : "#0673f7"};
cursor: ${props => props.type != 1 ? "pointer" : "default"};
white-space: nowrap;
text-align: center;
margin: 15px 15px;
${props => props.invisible && "width: 0; visibility: hidden;"}
text-overflow: ellipsis;
max-width: 100%;
overflow: hidden;
`

const TimesContainer = styled.div`
    font-family: "Helvetica Neue", Helvetica, Roboto;
    color: ${props => props.expired ? "#d23737" : "#6d6d6d"} ;
    font-size:14px;
    z-index: 10;
    pointer-events: none;
    mix-blend-mode: multiply;
    padding: 5px 10px 0 10px;
`

const DayTitleContainer = styled.div`
    z-index:2;
    justify-content:center;
    align-items:center;
    margin-bottom:10px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-items: center;
`

const NoHayCitasContainer = styled.div`
    text-align: center;
    padding-top: 30%;
    padding-bottom: 30%;
    background-color: #F4F5F5;
    flex:1;
    color: #848484;
    margin-bottom:20px;
    margin: 0 15px;
    border-radius: 7px;
`

const DoctorTitle = styled.div`
    font-weight:500;
    cursor: pointer;
    text-align: center;
    margin-bottom:15px;
    color: ${props => props.blocked ? "#c84b4e" : "#0673f7"};
`

const HorarioCita = styled(TimesContainer)`
    color: #fff;
    z-index:11;
    mix-blend-mode: normal;
`
const NoWrap = styled.div`
white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
`

const TodayMask = styled.div`
    position: absolute;
    top: -10px;
    height: calc(100vh - 250px);
    right: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00a6f9+0,7db9e8+100&0.08+0,0+100 */
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00a6f9+0,7db9e8+100&0.05+0,0+100 */
background: -moz-linear-gradient(top, rgba(0,166,249,0.05) 0%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(0,166,249,0.05) 0%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(0,166,249,0.05) 0%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0d00a6f9', endColorstr='#007db9e8',GradientType=0 ); /* IE6-9 */
`

const LineToday = styled.div`
        position: absolute;
    left: 0;
    right: 0;
    height: 1.3px;
    background-color: red;
    z-index: 10;
`

const LineDot = styled.div`
    position: absolute;
    left: 55px;
    width: 6px;
    height: 6px;
    background-color: red;
    border-radius: 10px;
    top: -3px;
`

const ModalCitaContainer = styled.div`
    display: flex;
    flex-direction:column;
`

const ModalCitaTitle = styled.div`
        text-align: center;
    margin: 5px 0 15px;
    font-weight: 500;
    color: #003a88;
    font-size: 17px;
`

const InputCita = styled(MyInput)`
    width: 100%;
    margin: 2px 0 7px;
    & input{
        border-radius: 3px;
        border: 1px solid #d4d4d4;
        font-size: 16px;
        padding: 6px 10px;
        box-sizing: border-box;
        width:100%;
        ${props => props.disabled && "background-color:#f5f5f5; color: transparent;"}
    }

`

const ContainerBotonesModalCita = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 13px;
`

const Boton = styled.button`
    background-color:transparent;
    color: ${props => props.color || "#0673f7"};
    border-radius: 3px;
    border: 1px solid ${props => props.color || "#0673f7"};
    font-size: 16px;
    padding: 6px 10px;
    box-sizing: border-box;
    margin-left:15px;
    ${props => props.disabled ? `
    cursor: default;
    opacity: .5;`: null}
`

const BotonCancelar = styled(Boton)`
    color: #d60000;
    border-color: #d60000;
    margin-left:0;
`

const HorariosModal = styled.div`
        color: #868686;
    font-weight: 400;
    margin-top: 5px;
`

const ValueModalCita = styled.div`
        margin-top: 3px;
`

const LabelCitaModal = styled.div`

`
const RowCitaModal = styled.div`
    ${props => {
        if (!props.edit)
            return `margin-bottom: 11px;
            padding: 0 10px 11px;
            border-bottom:1px solid #e8e8e8;`}}
    & ${LabelCitaModal}{
        ${props => {
        if (!props.edit)
            return `color: #868686;
            font-size: 14px;`
    }}
    }
`

const ModalCitasContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    text-align:center;
`

const DescCitaContainer = styled.a`
    margin-bottom: 0px;
    text-decoration: none;
    border-bottom: 1px solid #d8d8d8;
    padding: 10px 0 10px;
    width: 90%;
    box-sizing: border-box;
    color: #777777;
    &:last-child{
        border:0;
        margin-bottom:0;
    }
    &:first-child{
        margin-top:10px;
    }
    .no-touch &:hover{
        background-color:#ececec;
    }
`

const Ul = styled.ul`
columns: 2;
    list-style-type: none;
    padding: 0 0 0 2px;
    margin-bottom: 12px;
    & input{
        margin-right:5px;
    }
    & li{
        margin-bottom:4px;
    }
`

const AllExpired = styled.div`
margin: 0 15px 15px;
text-align: center;
color: #676767;
background-color: #efefef;
padding: 25px 15px;
border-radius: 7px;
font-size: 15px;
font-family: Roboto, "Helvetica Neue", Helvetica;
`

export default class Agenda extends React.Component {

    constructor(props) {
        super(props)
        this.getIntervals = this.getIntervals.bind(this)
        this.editCita = this.editCita.bind(this)
        this.closeModalCita = this.closeModalCita.bind(this)
        this.closeModalCitas = this.closeModalCitas.bind(this)
        this.changeService = this.changeService.bind(this)
        this.handleChangeRequirement = this.handleChangeRequirement.bind(this)
        this.saveCita = this.saveCita.bind(this)
        this.getDaysTimes = this.getDaysTimes.bind(this)
        this.updateCitas = this.updateCitas.bind(this)
        this.cancelCita = this.cancelCita.bind(this)
        this.getEditAvailableFor = this.getEditAvailableFor.bind(this)
        this.recalculate = this.recalculate.bind(this)
        console.log("constructing agenda")
        this.recalculate(props, this.nextMinute)
    }

    firstDay
    lastDay
    notExpired = []

    componentWillReceiveProps(nextProps) {
        if (this.props.position != nextProps.position || (this.props.business != nextProps.business) || !(this.props.date.isSame(nextProps.date, "day")) || (this.props.width != nextProps.width)) {
            console.log("receiving props re-construyendo", this.props, nextProps)
            this.props = nextProps
            this.recalculate(nextProps)
        } else if ((this.props.numberDaysCalculated != nextProps.numberDaysCalculated) && nextProps.numberDaysCalculated) {
            nextProps.numberDaysCalculated(this.state.sevenDays.length)
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state != nextState || this.props != nextProps)
            return true
        return false
    }

    recalculate(props, callback) {
        console.log("recalculating", callback)
        this.business = props.business
        this.state = {
            cita: null,
            sevenDays: [],
            loading: true,
            blockedDay: null,
            time: Date.now()
        }

        let days = Math.floor(this.props.width / 250)
        if (days > 7)
            days = 7
        if (days < 1)
            days = 1
        console.log("days", days)

        const today = this.props.date.clone()
        if(this.business.min_span){
            if(moment().isSame(today, 'day')){
                today.add(this.business.min_span, "days")
            }
            this.minimumDay = moment().add(this.business.min_span, "days")
        }
        let limitDay
        if(this.business.max_span){
            limitDay = moment()
            limitDay.add(this.business.max_span, 'days')
            this.limitDay = limitDay
        }
        const todayBuffer = today.clone()
        if (!this.props.hideCitas && this.props.position != "end")
            today.subtract(1, "days").day()
        if (!this.props.hideCitas && this.props.position == "end") {
            today.add(1, "days").day()
        }
        if (this.business.type != 1) {
            this.daysTimes = this.getDaysTimes(this.props.business.schedules)
            const daysTimes = this.daysTimes

            for (let i = 0, j = 0; i < days && j < (7 * 7); j++) {
                if (this.props.hideCitas) {
                    if(limitDay && today.isAfter(limitDay, 'day')){
                        console.log('entro a limit day', limitDay, today)
                        break;
                    }
                    i++
                    if (this.props.position == "end") {
                        this.state.sevenDays.unshift({
                            date: today.clone(),
                            intervals: []
                        })
                        today.subtract(1, "days").day()
                    } else {
                        this.state.sevenDays.push({
                            date: today.clone(),
                            intervals: []
                        })
                        today.add(1, "days").day()
                    }
                } else {
                    if (this.props.position == "initial" && this.state.sevenDays.length < 1 && daysTimes[todayBuffer.subtract(1, "days").day()]) {
                        //console.log("comp pushing")
                        i++
                        this.state.sevenDays.push({
                            date: todayBuffer,
                            intervals: []
                        })
                    } else if ((this.state.sevenDays.length > 0 || this.props.position != "initial") && (daysTimes[this.props.position == "end" ? today.subtract(1, "days").day() : today.add(1, "days").day()] || this.props.position == "start2" && this.state.sevenDays.length == 0 || this.props.position == "initial" && this.state.sevenDays.length == 1)) {
                        i++
                        if (this.props.position == "end") {
                            //console.log("comp unshifting")
                            this.state.sevenDays.unshift({
                                date: today.clone(),
                                intervals: []
                            })
                        } else {
                            //console.log("comp pushing")
                            this.state.sevenDays.push({
                                date: today.clone(),
                                intervals: []
                            })
                        }
                    } //else
                    //console.log("comp nothing")
                }
            }
            //console.log("comp sevendays", this.state.sevenDays)


        } else {
            let doctorsDaysTimes = {}
            this.business.doctors.forEach(doctor => {
                _.forEach(this.getDaysTimes(doctor.schedules), (day, key) => {
                    if (!doctorsDaysTimes[key]) {
                        doctorsDaysTimes[key] = []
                    }
                    doctorsDaysTimes[key].push(day)
                })
            })

            for (let i = 0, j = 0; i < days && j < (7 * 7); j++) {
                if (this.props.position == "initial" && this.state.sevenDays.length < 1) {
                    if (doctorsDaysTimes[today.day()].length > days)
                        break;
                    this.state.sevenDays.push({
                        date: today.clone(),
                        doctors: doctorsDaysTimes[today.day()] && doctorsDaysTimes[today.day()].map(dayTime => this.business.doctors.find(doctor => doctor.id == dayTime.doctors_id)) || []
                    })
                    if (doctorsDaysTimes[today.day()])
                        i += doctorsDaysTimes[today.day()].length
                    else
                        i++
                }
                else if ((this.state.sevenDays.length > 0 || this.props.position != "initial") && (doctorsDaysTimes[this.props.position == "end" ? today.subtract(1, "days").day() : today.add(1, "days").day()] || this.props.position == "start2" && this.state.sevenDays.length == 0 || this.props.position == "initial" && this.state.sevenDays.length == 1)) {
                    i += doctorsDaysTimes[today.day()] ? doctorsDaysTimes[today.day()].length : 1
                    if (i > days)
                        break;

                    if (this.props.position == "end") {
                        //console.log("comp unshifting")
                        this.state.sevenDays.unshift({
                            date: today.clone(),
                            doctors: doctorsDaysTimes[today.day()] && doctorsDaysTimes[today.day()].map(dayTime => this.business.doctors.find(doctor => doctor.id == dayTime.doctors_id)) || []
                        })
                    } else {
                        this.state.sevenDays.push({
                            date: today.clone(),
                            doctors: doctorsDaysTimes[today.day()] && doctorsDaysTimes[today.day()].map(dayTime => this.business.doctors.find(doctor => doctor.id == dayTime.doctors_id)) || []
                        })
                    }

                }
            }

            this.daysTimes = doctorsDaysTimes
            console.log("bug agenda", this.state.sevenDays, this.props.position, days, doctorsDaysTimes)
        }
        if (this.state.sevenDays.length < 1) {
            this.firstDay = this.props.date.clone()
            this.lastDay = this.props.date.clone()
        }
        else {
            this.firstDay = this.state.sevenDays[0].date
            this.lastDay = this.state.sevenDays[this.state.sevenDays.length - 1].date
        }

        if (this.props.numberDaysCalculated)
            this.props.numberDaysCalculated(this.state.sevenDays.length)

        console.log("recalculated", this.props.position, this.props.date, this.state.sevenDays)
        if (this.props.firstLastCallback)
            this.props.firstLastCallback(this.firstDay, this.lastDay)
        if (process.browser && this.state.sevenDays.length > 0)
            this.updateCitas(callback)
        else
            this.state.loading = false;
    }

    async updateCitas(callback, dates) {
        console.log("updating citas", callback)
        this.notExpired = []
        const daysTimes = this.daysTimes
        const upd = (dates) => {
            console.log("upd", dates)
            this.dates = dates
            const datesByDay = _.groupBy(dates, date => {
                date.agregada = undefined
                if (!(date.start_time instanceof moment))
                    date.start_time = moment(date.start_time, 'YYYY-MM-DD HH:mm:ss')
                if (!date.range)
                    date.range = moment.range(date.start_time.clone().add(1, "minutes"), date.start_time.clone().add(date.duration, "minutes"))
                return date.start_time.format('YYYY-MM-DD')
            })


            const newSevenDays = []
            this.state.sevenDays.forEach((day, key) => {
                if (this.business.type == 1) {
                    const doctors = []
                    day.doctors.forEach(doctor => {
                        const doctorBuffer = { ...doctor }
                        doctorBuffer.intervals = this.getIntervals(day.date, _.cloneDeep(daysTimes[day.date.day()].find(dayTime => dayTime.doctors_id == doctor.id)), datesByDay[day.date.format('YYYY-MM-DD')], doctor)
                        doctors.push(doctorBuffer)
                    })
                    newSevenDays.push({ date: day.date, doctors: doctors })
                } else {
                    const intervals = this.getIntervals(day.date, _.cloneDeep(daysTimes[day.date.day()]), datesByDay[day.date.format('YYYY-MM-DD')])
                    newSevenDays.push({ date: day.date, intervals: intervals })
                }
            })
            console.log("setting")
            this.setState({
                sevenDays: newSevenDays,
                loading: false
            }, () => {
                console.log("seted", this.state)
            })

            if (callback)
                callback()
        }

        if (dates) {
            upd(dates)
        } else
            api.get('/dates', {
                queryParams: {
                    id: this.business.id,
                    start: this.state.sevenDays[0].date.format('YYYY-MM-DD 00:00:00'),
                    end: this.state.sevenDays[this.state.sevenDays.length - 1].date.format('YYYY-MM-DD 23:59:59')
                }
            }).then((dates) => {

                upd(dates)
            })

    }

    componentDidMount() {
        console.log("agenda did mount")
    }

    componentDidUpdate() {
    }

    nextMinute = () => {
        if (this.notExpired.length > 0) {
            const notExpired = this.notExpired.shift()
            let timeToNextNotexpired = notExpired.start.valueOf() - Date.now()
            if (timeToNextNotexpired > 2147483647)
                timeToNextNotexpired = 2147483647
            console.log("setting timeout", timeToNextNotexpired)
            setTimeout(() => {
                console.log("timeout completed")
                this.updateCitas(this.nextMinute, this.dates)
            }, timeToNextNotexpired);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    getDaysTimes(schedules) {
        const schedulesByday = _.flatMap(schedules, (schedule) => {
            return schedule.days.map(day => {
                return { [day.day]: schedule }
            })
        })
        const schedulesBydayGrouped = _.groupBy(schedulesByday, (schedule) => {
            return Object.keys(schedule)[0]
        })

        console.log("schedulesByday", schedulesBydayGrouped)
        const daysFinale = {}
        _.forEach(schedulesBydayGrouped, (value, key) => {
            daysFinale[key] = value.map(val => ({ ...val[Object.keys(val)[0]] }))
            _.forEach(daysFinale[key], (v, k) => {
                daysFinale[key][k].opening_times = daysFinale[key][k].timeranges.map((timerange) => { const buff = moment.duration(timerange.opening_time); buff.schedule = { id: daysFinale[key][k].id, shrink: daysFinale[key][k].shrink, show_only: daysFinale[key][k].show_only }; return buff })
                daysFinale[key][k].closing_times = daysFinale[key][k].timeranges.map((timerange) => moment.duration(timerange.closing_time))
            })
        })

        const daysFinale2 = {}
        _.forEach(daysFinale, (value, key) => {
            _.forEach(value, (val, k) => {
                daysFinale2[key] = _.mergeWith(daysFinale2[key], val, (objValue, srcValue, key) => {
                    if (key == "all_hrs" && (objValue == 1 || srcValue == 1))
                        return 1
                    if (_.isArray(objValue))
                        return objValue.concat(srcValue);
                })
                delete daysFinale2[key].timeranges
                delete daysFinale2[key].days
            })
        })
        _.forEach(daysFinale2, (value, key) => {
            daysFinale2[key].opening_times = _.orderBy(daysFinale2[key].opening_times, Number, "asc")
            daysFinale2[key].closing_times = _.orderBy(daysFinale2[key].closing_times, Number, "desc")
        })
        console.log("daystimes", daysFinale2)
        return daysFinale2
    }

    getIntervals(date, actualDay, dates, doctor) {
        console.log("actualday", actualDay)
        const business = this.props.business
        const currentDate = date
        const doctorsId = doctor ? doctor.id : business.doctors_id ? business.doctors_id : null
        const interval = doctor ? moment.duration(doctor.interval, "minutes") : moment.duration(business.interval, "minutes")
        const intervals = []
        const now = new Date()
        if (actualDay)
            actualDay.opening_times.forEach((opening_time, i) => {
                const closingBuffer = actualDay.closing_times.pop()
                const closing_time = currentDate.hour(closingBuffer.hours()).minutes(closingBuffer.minutes()).seconds(0)
                const start = currentDate.clone().hour(opening_time.hours()).minutes(opening_time.minutes()).seconds(0)
                for (; start.clone().add(interval) <= closing_time;) {
                    const intervalBuffer = moment.range(start.clone(), start.add(interval))
                    intervalBuffer.citas = []
                    intervalBuffer.blocked = []
                    intervalBuffer.schedule = opening_time.schedule
                    intervalBuffer.expired = intervalBuffer.start.isBefore(now) ? true : false

                    let citasBuffer = _.filter(dates, cita => {
                        if (cita.doctors_id != doctorsId)
                            return false
                        return intervalBuffer.overlaps(cita.range)
                    })
                    citasBuffer.forEach(citaBuffer => {
                        if (!citaBuffer.agregada) {
                            intervalBuffer.citas.push(citaBuffer)
                            citaBuffer.agregada = true
                        } else
                            intervalBuffer.blocked.push(citaBuffer)
                    })
                    intervals.push(intervalBuffer)
                    if (!intervalBuffer.expired)
                        this.notExpired.push(intervalBuffer)
                }
                if (actualDay.opening_times[i + 1]) {
                    const nextOpening = currentDate.hour(actualDay.opening_times[i + 1].hours()).minutes(actualDay.opening_times[i + 1].minutes()).seconds(0)
                    const descanso = moment.range(start.clone(), nextOpening)
                    descanso.break = true
                    descanso.expired = descanso.start.isBefore(now) ? true : false
                    intervals.push(descanso)

                }
            })
        const simultaneous = doctor ? 1 : business.simultaneous
        const services = doctor ? doctor.services : business.services
        intervals.forEach((interval, index) => {
            interval.availableFor = []
            interval.editAvailableFor = []

            if (!interval.break && interval.blocked.length + interval.citas.length <= simultaneous) {
                if (business.use_services || doctor) {
                    if (interval.blocked.length + interval.citas.length < simultaneous) {
                        services.forEach(service => {
                            if (service.duration <= interval.diff("minutes")) {
                                interval.availableFor.push(service)
                            } else {
                                let blocked = false
                                for (let leftTime = service.duration - interval.diff("minutes"), i = 1; leftTime > 0; i++ , leftTime -= interval.diff("minutes")) {
                                    if (intervals[index + i] && !intervals[index + i].break) {
                                        if (intervals[index + i].citas.length + intervals[index + i].blocked.length >= simultaneous)
                                            blocked = true
                                    }
                                    else
                                        blocked = true
                                }
                                if (!blocked)
                                    interval.availableFor.push(service)
                            }
                        })
                    }
                } else if (interval.blocked.length + interval.citas.length < simultaneous) {
                    if (business.duration <= interval.diff("minutes"))
                        interval.availableFor.push({ duration: business.duration })
                    else {
                        let blocked = false
                        for (let leftTime = business.duration - interval.diff("minutes"), i = 1; leftTime > 0; i++ , leftTime -= interval.diff("minutes")) {
                            if (intervals[index + i] && !intervals[index + i].break) {
                                if (intervals[index + i].citas.length + intervals[index + i].blocked.length >= simultaneous)
                                    blocked = true
                            }
                            else
                                blocked = true
                        }
                        if (!blocked)
                            interval.availableFor.push({ duration: business.duration })
                    }
                }
            }
        })

        return intervals
    }

    getEditAvailableFor(intervals, index, idCita, doctor) {
        const business = this.props.business
        const editAvailableFor = []
        const interval = intervals[index]
        const simultaneous = doctor ? 1 : business.simultaneous
        const services = doctor ? doctor.services : business.services

        if (!interval.break && interval.blocked.length + interval.citas.length <= simultaneous) {
            if (business.use_services || doctor) {
                if (interval.blocked.length + interval.citas.length <= simultaneous) {
                    services.forEach(service => {
                        if (service.duration <= interval.diff("minutes")) {
                            editAvailableFor.push(service)
                        } else {
                            let blocked = false
                            for (let leftTime = service.duration - interval.diff("minutes"), i = 1; leftTime > 0; i++ , leftTime -= interval.diff("minutes")) {
                                if (intervals[index + i] && !intervals[index + i].break) {
                                    if (_.filter(intervals[index + i].citas, cita => cita.id != idCita).length + _.filter(intervals[index + i].blocked, cita => cita.id != idCita).length >= simultaneous)
                                        blocked = true
                                }
                                else
                                    blocked = true
                            }
                            if (!blocked)
                                editAvailableFor.push(service)
                        }
                    })
                }
            }
        }
        return editAvailableFor
    }

    editCita({ start_time, doctors_id, requirementsDates, duration, service, id, email, name, status, blockedMoment }, preview = false) {
        const cita = {
            blockedMoment,
            id: id,
            email,
            name,
            start_time: start_time,
            doctors_id: doctors_id,
            status,
            businesses_id: this.business.id,
            requirementsDates: requirementsDates ? requirementsDates : this.business.requirements.map((requirement, i) => {
                return {
                    name: requirement.name,
                    value: null
                }
            })
        }

        if (this.business.use_services == 1 && this.business.services[0] || this.business.type == 1) {
            cita.service = service ? service : this.props.service ? this.props.service.name : this.availableFor && this.availableFor[0].name
            cita.duration = duration ? duration : this.props.service ? this.props.service.duration : this.availableFor && this.availableFor[0].duration
        } else {
            cita.duration = duration ? duration : this.business.duration
        }
        this.setState({
            preview: preview,
            edit: id ? false : true,
            cita
        }, this.autoComplete)
    }

    autoComplete = () => {
        console.log("autocomplete called", this.dialogId)
        if (!this.auto && this.state.edit && !this.props.hideCitas) {
            this.auto = new autoComplete({
                dialogId: "mask-" + this.dialogId,
                selector: '#nameInput',
                minChars: 1,
                source: function (term, response) {
                    api.get("/clients", {
                        queryParams: {
                            name: term
                        }
                    }).then(res => {
                        console.log(res)
                        const onlyName = res.map(client => client.name)
                        console.log("onlyname", onlyName)
                        response(onlyName)
                    })
                },
                onSelect: (e, term, item) => {
                    e.preventDefault()
                    api.get("/client", {
                        queryParams: {
                            name: term
                        }
                    }).then(res => {
                        console.log(res)
                        this.setState({
                            cita: update(this.state.cita, {
                                name: { $set: res.name },
                                email: { $set: res.email }
                            })
                        }, () => {
                            setTimeout(() => {
                                if (this.botonGuardar)
                                    this.botonGuardar.focus()
                            }, 20);
                        })
                        this.state.cita.requirementsDates.forEach((requirement, index) => {
                            const requirementBusiness = this.props.business.requirements.find(req => req.name == requirement.name)
                            if (requirementBusiness && res[requirementBusiness.related_to])
                                this.setState({
                                    cita: update(this.state.cita, {
                                        requirementsDates: { [index]: { value: { $set: res[requirementBusiness.related_to] } } }
                                    })
                                })
                        })
                    })
                }
            });
            console.log("autocomplete created", this.auto)
        } else if (this.auto && !this.state.edit) {
            this.auto.destroy()
            this.auto = null
        }
    }

    closeModalCita() {
        if (this.interval)
            this.interval.clicked = false
        this.setState({
            cita: null,
            edit: false
        }, this.autoComplete)
    }

    closeModalCitas() {
        this.setState({
            citas: null
        })
    }

    changeService(name, duration) {
        this.setState({
            cita: update(this.state.cita, {
                service: { $set: name },
                duration: { $set: duration }
            })
        })
    }

    handleChangeRequirement(event, index) {
        const value = event.target.value
        this.setState({
            cita: update(this.state.cita, {
                requirementsDates: { [index]: { value: { $set: value } } }
            })
        })
    }

    handleChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({
            cita: update(this.state.cita, {
                [name]: { $set: value }
            })
        })
    }

    handleChangeName = (event) => {

        this.handleChange(event)
    }

    saveCita() {
        let ref = { ...this.state.cita, loading: true }
        if (ref.id)
            this.cita.loading = true
        else
            this.cita = null
        if (this.state.cita.id)
            this.dates.forEach((date, index) => { if (date.id == this.state.cita.id) this.dates[index] = ref })
        else {
            this.dates.push(ref)
        }
        this.updateCitas(null, this.dates)
        this.closeModalCita()
        api.post("/date", {
            body: JSON.stringify({ ...this.state.cita, last_action_by: this.props.hideCitas ? "visitor" : "web", start_time: this.state.cita.start_time.format('YYYY-MM-DD HH:mm:ss') })
        }).then(date => {
            if (this.cita) {
                this.cita.loading = false
                this.cita = null
            }
            ref.loading = false
            this.dates[this.dates.indexOf(ref)] = date
            if (this.props.callbackOnSaveCita)
                this.props.callbackOnSaveCita(date)
            else
                this.updateCitas(this.props.callbackOnUpdateCita, this.dates)
        })
    }

    confirmCita = () => {
        this.cita.loading = true;
        this.closeModalCita()
        api.post("/confirm_date", {
            body: JSON.stringify({
                id: this.cita.id
            })
        }).then(res => {
            this.cita.loading = false
            this.cita.status = "confirmed"
            this.cita = null
            this.forceUpdate()
        })
    }

    cancelCita(id) {
        this.cita.loading = true
        const ref = { ...this.cita }
        this.closeModalCita()
        api.delete('/date', {
            queryParams: {
                id: id,
                from: "web"
            }
        }).then((res) => {
            this.cita.loading = false
            this.dates.splice(this.dates.findIndex(date => date.id == id), 1)
            this.updateCitas(this.props.callbackOnUpdateCita, this.dates)
        })
    }

    editBlockDay = (blockedDay = null) => {
        this.setState({
            blockedDay
        })
    }

    blockMoment = (blockedMoment = null, callback) => {
        console.log("blocking moment", callback)
        api.post("/block_day", {
            body: JSON.stringify({
                block: blockedMoment.block,
                businesses_id: this.props.business.id,
                doctors_id: blockedMoment.doctors_id,
                from: blockedMoment.from.format('YYYY-MM-DD HH:mm:ss'),
                to: blockedMoment.to.format('YYYY-MM-DD HH:mm:ss')
            })
        }).then(res => {
            this.closeModalCita()
            if (callback)
                callback()
        })
    }

    blockDay = (callback) => {
        console.log("blocking day")
        api.post("/block_day", {
            body: JSON.stringify({
                block: !this.state.blockedDay.blocked,
                businesses_id: this.props.business.id,
                doctors_id: this.state.blockedDay.doctors_id,
                from: this.state.blockedDay.from.format('YYYY-MM-DD HH:mm:ss'),
                to: this.state.blockedDay.to.format('YYYY-MM-DD HH:mm:ss')
            })
        }).then(res => {
            this.editBlockDay()
            if (callback) {
                console.log("calling callback", callback)
                callback()
            }
        })
    }

    static defaultProps = { hideCitas: false, position: "initial" }

    render() {
        //console.log("rendering agenda")
        const business = this.props.business
        const sevenDays = this.state.sevenDays

        function intervalsAreSame(x, y) {
            var objectsAreSame = true;
            if (x.length != y.length) {
                objectsAreSame = false;
            } else
                for (var index in x) {
                    if (x[index].start.format("HH:mm") !== y[index].start.format("HH:mm") || x[index].end.format("HH:mm") !== y[index].end.format("HH:mm")) {
                        objectsAreSame = false;
                        break;
                    }
                }
            return objectsAreSame;
        }

        const printTimes = (intervals, factor) => {
            return <TimesContainer>{intervals.map((interval, index) => {
                return <>
                    <TimeInterval style={{ height: interval.break ? "50px" : interval.diff("minutes") * factor + "px" }} key={index}>
                        {!interval.break && <StartingTime>{interval.start.format("HH:mm")}</StartingTime>}
                    </TimeInterval>
                    {!intervals[index + 1] && <TimeInterval key={index}>
                        <StartingTime>{interval.end.format("HH:mm")}</StartingTime>
                    </TimeInterval>}
                </>
            }
            )}</TimesContainer>
        }

        const printIntervals = (intervals, doctor, blocked, blockedDays = []) => {
            console.log("render esto 2")
            const { active } = this.props
            const factor = 50 / (!!doctor && doctor.interval || business.interval)
            if (intervals.length < 1) {
                return <NoHayCitasContainer>No hay horarios para este día</NoHayCitasContainer>
            }
            const positionated = []
            let intervalsCount = 0
            this.actualSchedule = {
                id: null
            }
            const intervalsMapped = intervals.map((interval, index) => {
                let blockedMoment
                const rangeWithoutExtremes = moment.range(interval.start.clone().add(1, "minute"), interval.end.clone().subtract(1, "minute"))

                for (let blockedDay of blockedDays) {
                    if (blockedDay.momentRange && blockedDay.momentRange.intersect(rangeWithoutExtremes) && (!doctor || doctor.id == blockedDay.doctors_id)) {
                        console.log("blocking moment")
                        blockedMoment = true
                        break;
                    }
                }
                let xOffset = null;
                //console.log("actualSchedule", this.actualSchedule, interval)
                if (interval.break) {
                    this.actualSchedule = {
                        id: null
                    }
                } else if (this.actualSchedule.id != interval.schedule.id)
                    this.actualSchedule = { ...interval.schedule }

                if (!this.props.hideCitas && interval.citas && interval.citas.length > 0) {
                    for (let iPos in positionated) {
                        const position = positionated[iPos]
                        if (position) {
                            let stay = false
                            for (let iBlocked in interval.blocked) {
                                if (interval.blocked[iBlocked].id == position.id) {
                                    stay = true;
                                    break;
                                }
                            }
                            if (!stay) {
                                positionated[iPos] = null
                                console.log("offset out", positionated[iPos], positionated)
                                position = null
                            }
                        }
                        if (position == null) {
                            console.log("offset entro", position, iPos, xOffset, positionated)
                            xOffset = 10 * iPos
                            positionated[iPos] = _.maxBy(interval.citas, 'duration')
                            break
                        }
                    }
                    console.log("offset positionate", positionated, xOffset)
                    if (xOffset == null) {
                        xOffset = positionated.length * 10
                        positionated.push(_.maxBy(interval.citas, 'duration'))
                        console.log("setting offset", xOffset, positionated)
                    }
                    console.log("offset", xOffset)
                }
                const mask = (this.props.service && !interval.availableFor.find(service => service.duration == this.props.service.duration)) || (this.props.hideCitas && (interval.expired || blocked))
                const propsInterval = {
                    descanso: interval.break,
                    blocked: interval.availableFor.length < 1,
                    blockedByUser: blocked,
                    mask: mask ? "true" : undefined,
                    active,
                    blockedMoment
                }
                let marginRight = 30 - xOffset
                if (marginRight < 10)
                    marginRight = 10
                if (this.props.hideCitas && this.actualSchedule.shrink) {
                    if (this.actualSchedule.show_only < 1) {
                        return null
                    } else if (!mask && !interval.expired && !interval.break) {
                        this.actualSchedule.show_only--
                    }
                }
                if (this.props.hideCitas && (this.state.sevenDays.length == 1 && interval.expired))
                    return null
                intervalsCount++
                return <IntervalContainer className={"interval" + index} key={index}>
                    {((mask && !interval.break) || (this.props.hideCitas && blockedMoment)) && <Mask />}
                    <Interval onClick={interval.availableFor.length > 0 && !mask && !blocked && active ? () => {
                        this.doctor = doctor
                        interval.clicked = true
                        this.interval = interval
                        this.availableFor = interval.availableFor
                        this.editCita({
                            blockedMoment,
                            start_time: interval.start,
                            doctors_id: doctor ? doctor.id : business.doctors_id ? business.doctors_id : null
                        })
                    } : undefined} {...propsInterval} clicked={interval.clicked} hideCitas={this.props.hideCitas} key={index} style={{ height: interval.break ? "50px" : interval.diff("minutes") * factor + "px" }}>
                        <TimesContainer expired={!this.props.hideCitas && interval.expired} >
                            <TimeInterval style={{ height: interval.break ? "50px" : interval.diff("minutes") * factor + "px" }} key={index}>
                                {!interval.break && <StartingTime>{interval.start.format("HH:mm")}</StartingTime>}
                            </TimeInterval>
                        </TimesContainer>
                        {/* {interval.expired && <Expired />} */}
                    </Interval>
                    {!this.props.hideCitas && interval.citas && interval.citas.length > 0 && <CitaContainer style={{ marginRight, marginLeft: xOffset, height: _.maxBy(interval.citas, 'duration').duration * factor + "px" }} ><Cita onClick={!mask ? () => {
                        this.doctor = doctor
                        this.cita = interval.citas[0]
                        if (interval.citas.length == 1) {
                            this.availableFor = this.getEditAvailableFor(intervals, index, interval.citas[0].id, doctor)
                            this.editCita(interval.citas[0])
                        } else {
                            this.intervals = intervals
                            this.index = index
                            this.setState({
                                citas: interval.citas
                            })
                        }
                    } : undefined} noClick={mask} status={_.find(interval.citas, function (cita) { return cita.status == "active"; }) ? "active" : "confirmed"} >
                        {interval.citas[0].loading && <CitaPosition><div><i className="fa fa-spinner fa-spin fa-1x fa-fw" /></div></CitaPosition> || interval.citas.length == 1 && <CitaPosition>
                            <NoWrap style={{ fontWeight: 600, fontFamily: "'Helvetica Neue', Helvetica, Roboto", marginBottom: 4 }}>{interval.citas[0].name}</NoWrap><NoWrap style={{ color: "#e0e0e0", fontSize: 13, marginBottom: 4, overflow: "visible" }} >{interval.citas[0].email}</NoWrap>
                        </CitaPosition> || (business.simultaneous > 1 || interval.citas.length > 1) && !doctor && <CitaPosition style={{ fontWeight: 700 }} >{interval.citas.length} Citas</CitaPosition>}
                    </Cita></CitaContainer>}
                    {this.props.today && !this.props.hideCitas && intervals.length > 0 && interval.contains(this.props.today, { excludeStart: false, excludeEnd: true }) && <LineToday style={{ top: this.props.today.diff(interval.start, "minutes") * (interval.break && (50 / interval.diff("minutes")) || factor) - (index > 0 && 1 || 0) }} ><LineDot /></LineToday>}
                </IntervalContainer>
            }
            )
            return <div style={{ position: "relative" }}>
                {intervalsCount > 0 && intervalsMapped || <AllExpired>No hay mas citas disponibles para el día de hoy</AllExpired>}
            </div>
        }

        const checkBlockedDays = (day, blockedDays, doctors_id) => {
            console.log("entro a checkblockeddays")
            for (let blockedDay of blockedDays) {
                if (doctors_id && (blockedDay.doctors_id != doctors_id)) {
                    console.log("not blocked by moment", day.date.format('YYYY-MM-DD HH:mm:ss'), blockedDay, doctors_id)
                    continue;
                }
                if (blockedDay.momentRange && day.intervals.length > 0) {
                    console.log("entro a momentrange", blockedDay.momentRange)
                    if (blockedDay.momentRange.start.isSame(day.intervals[0].start, "minute") && blockedDay.momentRange.end.isSame(day.intervals[day.intervals.length - 1].end, "minute")) {
                        day.blocked = true
                        console.log("entro a momentrange 2")
                        break;
                    }
                    console.log("termino moment range")
                }
                else if (blockedDay.moment && blockedDay.moment.isSame(day.date, "day")) {
                    console.log("entro a mismo dia")
                    day.blocked = blockedDay.moment
                    console.log("blocked by moment", day.date.format('YYYY-MM-DD HH:mm:ss'), blockedDay)
                    break;
                }
                console.log("no entro a ninguno")
            }
        }
        const services = business.type != 1 ? business.services : this.doctor && this.doctor.services
        return <Query fetchPolicy={"cache-and-network"} query={getBlockedDays} variables={{ doctors_id: business.doctors_id, businesses_id: business.id, from: this.firstDay.format('YYYY-MM-DD 00:00:00'), to: this.lastDay.format('YYYY-MM-DD 23:59:59') }}>
            {({ loading, error, data={}, refetch }) => {
                let blockedDays = []
                if (data.blockedDays) {
                    data.blockedDays.forEach((blockedDay, iBlockedDay) => {
                        if (blockedDay.to) {
                            blockedDays.push({ ...blockedDay, momentRange: moment.range(moment(blockedDay.from, 'YYYY-MM-DD HH:mm:ss'), moment(blockedDay.to, 'YYYY-MM-DD HH:mm:ss')) })
                        } else
                            blockedDays.push({ ...blockedDay, moment: moment(blockedDay.from, 'YYYY-MM-DD HH:mm:ss') })
                    })

                }
                console.log("render esto 1")
                return (
                    <div>
                        <Modal width={420} idCallback={(id) => { this.dialogId = id }} visible={this.state.cita ? true : false} onClose={this.closeModalCita} >
                            {this.state.cita && <Formsy onValidSubmit={this.saveCita} >
                                <input id="username" style={{ display: 'none' }} type="tell" name="username" />
                                <input id="password" style={{ display: 'none' }} type="password" name="password" />
                                <ModalCitaContainer>
                                    <ModalCitaTitle>{this.state.cita.start_time.format("dddd D MMMM, YYYY")}
                                        <HorariosModal>{this.state.cita.start_time.format("HH:mm")} - {this.state.cita.start_time.clone().add(this.state.cita.duration, "minutes").format("HH:mm")}
                                            <div>
                                                {(!this.state.edit || this.props.hideCitas) && this.state.cita.service}
                                                {(this.doctor || business.doctors_id) && <span style={{ fontWeight: 500, color: "#696969" }} > {(this.doctor && this.doctor.name) || business.name} </span>}
                                            </div>
                                        </HorariosModal>
                                    </ModalCitaTitle>
                                    {(business.use_services == 1 || business.type == 1) && !this.props.hideCitas && this.state.edit && <Ul>
                                        {services && services.map((service, i) => {
                                            const available = this.availableFor.findIndex(serv => serv.duration == service.duration) > -1
                                            return <Service available={available} key={i}><label><input disabled={!available || this.state.cita.blockedMoment} onChange={() => { this.changeService(service.name, service.duration) }} checked={this.state.cita.service == service.name ? true : false} type="radio" name="service" />{service.name}</label></Service>
                                        })}
                                    </Ul>}
                                    {!this.props.hideCitas && <div style={{ marginBottom: 7, fontWeight: 400, color: "#5b5b5b", fontSize: 16, marginTop: 10, textAlign: "center" }}>Datos del cliente</div>}
                                    <div style={{ ...this.props.hideCitas ? null : { backgroundColor: "#f5f5f5", padding: 10, borderRadius: 5 } }} >
                                        <RowCitaModal edit={this.state.edit}><LabelCitaModal>Nombre</LabelCitaModal>
                                            {this.state.edit && <InputCita disabled={this.state.cita.blockedMoment} id="nameInput" validations="maxLength:45" validationError="45 caracteres máximo" required onChange={this.handleChangeName} name={"name"} value={this.state.cita.name} type="text" /> || <ValueModalCita>{this.state.cita.name}</ValueModalCita>}
                                        </RowCitaModal>
                                        <RowCitaModal edit={this.state.edit}><LabelCitaModal>Email</LabelCitaModal>
                                            {this.state.edit && <InputCita disabled={this.state.cita.blockedMoment} required validations="isEmail" validationError="Email invalido" onChange={this.handleChange} name={"email"} value={this.state.cita.email} type="email" /> || <ValueModalCita>{this.state.cita.email}</ValueModalCita>}
                                        </RowCitaModal>
                                        {this.state.cita.requirementsDates.map((requirement, i) =>
                                            <RowCitaModal key={i} edit={this.state.edit}><LabelCitaModal>{requirement.name}</LabelCitaModal>
                                                {this.state.edit && <InputCita disabled={this.state.cita.blockedMoment} validations="maxLength:45" validationError="45 caracteres máximo" required onChange={(e) => { this.handleChangeRequirement(e, i) }} name={requirement.name} value={requirement.value} type="text" /> || <ValueModalCita>{requirement.value}</ValueModalCita>}
                                            </RowCitaModal>
                                        )}
                                    </div>
                                    {!this.state.preview && <ContainerBotonesModalCita>
                                        {this.state.cita.id && !this.state.edit && <BotonCancelar type="button" onClick={() => { this.cancelCita(this.state.cita.id) }} >Cancelar cita</BotonCancelar>}
                                        {!this.state.cita.id && !this.props.hideCitas && <BotonCancelar onClick={(e) => { e.preventDefault(); this.blockMoment({ doctors_id: this.state.cita.doctors_id, block: this.state.cita.blockedMoment ? false : true, from: this.state.cita.start_time, to: this.state.cita.start_time.clone().add(this.state.cita.duration, "minutes") }, refetch) }}>{this.state.cita.blockedMoment ? "Desbloquear" : "Bloquear"}</BotonCancelar>}
                                        <div style={{ flex: 1, textAlign: "right" }} >{this.state.edit && this.state.cita.id && <Boton type="button" color="#3a3a3a" onClick={() => { this.setState({ edit: false }, this.autoComplete) }} >Regresar</Boton>}
                                            {this.state.edit && !this.state.cita.id && <Boton type="button" color="#3a3a3a" onClick={this.closeModalCita} >{this.state.cita.blockedMoment ? "Salir" : "Cancelar"}</Boton>}
                                            {!this.state.cita.blockedMoment && (this.state.edit && <Boton disabled={this.state.cita.blockedMoment} ref={ref => this.botonGuardar = ref} >Guardar</Boton> || <><Boton type="button" onClick={(e) => { e.preventDefault(); this.setState({ edit: true }, this.autoComplete); return false }} >Editar</Boton>{this.state.cita.status == "active" && <Boton color="#06a917" type="button" onClick={this.confirmCita} >Confirmar</Boton>}</>)}
                                        </div>
                                    </ContainerBotonesModalCita>}
                                </ModalCitaContainer>
                            </Formsy>
                            }
                        </Modal>
                        <Modal visible={this.state.citas ? true : false} onClose={this.closeModalCitas}>
                            <ModalCitasContainer>
                                <ModalCitaTitle>Citas del dia {this.state.citas && this.state.citas[0].start_time.format("ddd D MMM HH:mm")}</ModalCitaTitle>
                                {this.state.citas && this.state.citas.map(cita =>
                                    <DescCitaContainer href="" onClick={(e) => {
                                        e.preventDefault()
                                        this.closeModalCitas()
                                        this.availableFor = this.getEditAvailableFor(this.intervals, this.index, cita.id)
                                        this.cita = cita
                                        this.editCita(cita)
                                    }}>
                                        <div style={{ color: "#3e3e3e", fontWeight: 500 }} >{cita.name}</div>
                                        <div>{cita.email}</div>
                                        <div>{cita.service}</div>
                                    </DescCitaContainer>
                                )}
                            </ModalCitasContainer>
                        </Modal>
                        <Modal visible={this.state.blockedDay ? true : false} onClose={() => this.editBlockDay()} >
                            <div>Día {this.state.blockedDay && this.state.blockedDay.from.format("ddd D MMM")}{this.state.blockedDay && this.state.blockedDay.doctor && " " + this.state.blockedDay.doctor.name}</div>
                            <button onClick={() => { this.blockDay(refetch) }} >{this.state.blockedDay && this.state.blockedDay.blocked ? "Desbloquear" : "Bloquear"} día</button>
                        </Modal>

                        {!this.state.loading && <MainWrapper>{sevenDays.map((day, indexDay) => {
                            day.blocked = false
                            if (business.doctors_id)
                                day.doctor = {
                                    id: business.doctors_id
                                }
                            if (business.type != 1)
                                checkBlockedDays(day, blockedDays, business.doctors_id)

                            const contiguous = sevenDays[indexDay + 1] && sevenDays[indexDay + 1].date.diff(day.date, "days") > 1 ? false : true;

                            let showTimes = true
                            //sevenDays[indexDay - 1] ? console.log(day.date.format("ddd D MMM") + " vs " + sevenDays[indexDay - 1].date.format("ddd D MMM")) : console.log(day.date.format("ddd D MMM") + " vs null")
                            //if (day.intervals)
                            //sevenDays[indexDay - 1] && intervalsAreSame(day.intervals, sevenDays[indexDay - 1].intervals) ? showTimes = false : showTimes = true
                            console.log('rendering limit', this.minimumDay, day.date)
                            return <DayContainer className={"day" + indexDay} type={business.type} key={indexDay}>
                                {this.props.today && this.props.today.isSame(day.date, "day") && false && <TodayMask />}
                                <DayTitleContainer>{indexDay == 0 && (!this.minimumDay || day.date.isAfter(this.minimumDay, 'day')) && this.props.botonLeft}<DayTitle style={{ ...this.props.hideCitas && { cursor: "auto" } }} today={this.props.today && this.props.today.isSame(day.date, "day")} type={business.type} blocked={!this.props.hideCitas && day.blocked} onClick={() => { !this.props.hideCitas && business.type != 1 && this.editBlockDay({ doctors_id: this.props.business.doctors_id, from: day.intervals[0].start, to: day.intervals[day.intervals.length - 1].end, blocked: day.blocked }) }} >{day.date.format("dddd D MMMM, YYYY")}</DayTitle>{indexDay == sevenDays.length - 1 && (!this.limitDay || day.date.isBefore(this.limitDay, 'day')) && this.props.botonRight}</DayTitleContainer>
                                {day.intervals && printIntervals(day.intervals, null, day.blocked, blockedDays) ||
                                    day.doctors.length > 0 && <WrapperHorizontal>
                                        {day.doctors.map((doctor, indexDoctor) => {
                                            const blockedDay = {
                                                doctors_id: doctor.id,
                                                blocked: false,
                                                date: day.date,
                                                intervals: doctor.intervals,
                                                from: doctor.intervals[0].start, 
                                                to: doctor.intervals[doctor.intervals.length - 1].end
                                            }
                                            checkBlockedDays(blockedDay, blockedDays, doctor.id)
                                            return <div style={{ flex: 1 }} key={doctor.id}>
                                                <DoctorTitle onClick={() => this.editBlockDay(blockedDay)} blocked={blockedDay.blocked} >{doctor.name}</DoctorTitle>
                                                {doctor.intervals && printIntervals(doctor.intervals, doctor, blockedDay.blocked, blockedDays)}
                                            </div>
                                        }
                                        )}
                                    </WrapperHorizontal>
                                }
                            </DayContainer>
                        }
                        )}</MainWrapper> || <div style={{ textAlign: "center", marginTop: 60, marginBottom: 20, fontSize: 30, color: "#a2a2a2" }} ><i className="fa fa-spinner fa-spin fa-1x fa-fw" /></div>}
                    </div>
                );
            }}
        </Query>
    }

}